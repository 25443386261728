const questions = [
  // 1 Слайд
  {
    questionText:
      "Akhirnya semua barang sudah dikemas! Cuma saja, saya tidak bisa membongkar lemari sendirian.",
    answerOptions: [
      {
        answerText:
          "Saya tinggalkan lemari ini di sini. Hidup baru perlu mebel baru.",
        answerResult:
          "Lemarinya masih bagus, sayang kalau ditinggal. Sementara itu, teman-teman bisa saja sedang sibuk sekarang. Saya akan menggunakan jasa Helper. Sementara Helper bekerja, saya akan punya waktu untuk membereskan barang-barang lama.",
        result: false,
      },
      {
        answerText: "Saya akan telepon teman, mereka pasti bantu.",
        answerResult:
          "Lemarinya masih bagus, sayang kalau ditinggal. Sementara itu, teman-teman bisa saja sedang sibuk sekarang. Saya akan menggunakan jasa Helper. Sementara Helper bekerja, saya akan punya waktu untuk membereskan barang-barang lama.",
        result: false,
      },
      {
        answerText: "Saya akan pesan jasa Helper di aplikasi.",
        answerResult:
          "Lemarinya masih bagus, sayang kalau ditinggal. Sementara itu, teman-teman bisa saja sedang sibuk sekarang. Saya akan menggunakan jasa Helper. Sementara Helper bekerja, saya akan punya waktu untuk membereskan barang-barang lama.",
        result: true,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд

  {
    questionText:
      "Ini akan saya bawa, dan ini — masuk ke tempat sampah. Cuma tinggal satu yang pastinya tidak akan saya bawa.",
    answerOptions: [
      {
        answerText:
          "Biarkan penghuni baru yang mengurusi barang-barang lama ini.",
        answerResult:
          "Meminta bantuan Helper — ini opsi yang bagus. Saya akan berikan tanda suka untuk Helper atas kinerjanya yang baik di aplikasi Maxim.",
        result: false,
      },
      {
        answerText:
          "Sementara Helper-nya belum pergi, saya akan minta bantuan untuk membuang barang.",
        answerResult:
          "Meminta bantuan Helper — ini opsi yang bagus. Saya akan berikan tanda suka untuk Helper atas kinerjanya yang baik di aplikasi Maxim.",
        result: true,
      },
      {
        answerText: "Saya mau buat obral untuk barang-barang ini di garasi.",
        answerResult:
          "Meminta bantuan Helper — ini opsi yang bagus. Saya akan berikan tanda suka untuk Helper atas kinerjanya yang baik di aplikasi Maxim.",
        result: false,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд
  {
    questionText:
      "Waktunya untuk angkut barang ke rumah baru! Menurut kamu gimana, bisa atau tidak ya, mengangkut semuanya sekaligus?",
    answerOptions: [
      {
        answerText: "Mustahil, di sini 3 truk juga belum tentu cukup.",
        answerResult:
          "Untuk barang-barang ini, mobil truk standar cukup. Pilih tarif Cargo dan pesan lagi jasa Helper karena bantuan untuk bongkar muat pasti diperlukan.",
        result: false,
      },
      {
        answerText: "Barangnya tidak begitu banyak, mobil ringan pun cocok.",
        answerResult:
          "Untuk barang-barang ini, mobil truk standar cukup. Pilih tarif Cargo dan pesan lagi jasa Helper karena bantuan untuk bongkar muat pasti diperlukan.",
        result: false,
      },
      {
        answerText: "Satu mobil truk tampaknya cukup. ",
        answerResult:
          "Untuk barang-barang ini, mobil truk standar cukup. Pilih tarif Cargo dan pesan lagi jasa Helper karena bantuan untuk bongkar muat pasti diperlukan.",
        result: true,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд
  {
    questionText:
      "Teman saya janji akan menemui pengemudi dan tukang bongkar muat di rumah baru. Bagaimana caranya supaya teman saya tahu kapan mereka tiba?",
    answerOptions: [
      {
        answerText:
          "Gampang! Bisa hitung waktu perjalanan dan langsung telepon teman.",
        answerResult:
          "Bisa mengandalkan naluri atau menghitung waktu, tetapi lebih aman kalau kirim tautan ke rute. Melihat lokasi mobil berisi barang memang lebih nyaman di peta daring.",
        result: false,
      },
      {
        answerText: "Cukup andalkan naluri penguin.",
        answerResult:
          "Bisa mengandalkan naluri atau menghitung waktu, tetapi lebih aman kalau kirim tautan ke rute. Melihat lokasi mobil berisi barang memang lebih nyaman di peta daring.",
        result: false,
      },
      {
        answerText: "Lihat lokasi mobil dengan barang di peta daring.",
        answerResult:
          "Bisa mengandalkan naluri atau menghitung waktu, tetapi lebih aman kalau kirim tautan ke rute. Melihat lokasi mobil berisi barang memang lebih nyaman di peta daring.",
        result: true,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд
  {
    questionText:
      "Saya sudah menyerahkan kunci kepada penghuni baru. Waktunya berangkat ke rumah baru!",
    answerOptions: [
      {
        answerText:
          "Saya akan pesan Bike supaya lebih cepat sampai dan bisa membongkar barang.",
        answerResult:
          "Pindahan — bukan perkara mudah. Kelihatannya si penguin tidak sanggup mengurus barang pindahan sendirian. Harus buru-buru ke sana. ",
        result: true,
      },
      {
        answerText: "Saya mau jalan kaki.",
        answerResult:
          "Pindahan — bukan perkara mudah. Kelihatannya si penguin tidak sanggup mengurus barang pindahan sendirian. Harus buru-buru ke sana. ",
        result: false,
      },
      {
        answerText: "Saya tidak mau pergi.",
        answerResult:
          "Pindahan — bukan perkara mudah. Kelihatannya si penguin tidak sanggup mengurus barang pindahan sendirian. Harus buru-buru ke sana. ",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 6 слайд
  {
    questionText:
      "Tepat waktu! Tukang bongkar muat membantu pinguin membawa seluruh barang ke dalam. Bagaimana cara mengucapkan terima kasih atas pekerjaannya yang bagus? ",
    answerOptions: [
      {
        answerText: "Tambahkan sebagai teman di Facebook",
        answerResult:
          "Bercakap-cakap, pastinya menyenangkan. Tukang bongkar muat pasti juga senang mendapatkan uang tip atas pekerjaannya. Jumlahnya akan ditambahkan ke harga order.",
        result: false,
      },
      {
        answerText: "Berikan uang tip.",
        answerResult:
          "Bercakap-cakap, pastinya menyenangkan. Tukang bongkar muat pasti juga senang mendapatkan uang tip atas pekerjaannya. Jumlahnya akan ditambahkan ke harga order.",
        result: true,
      },
      {
        answerText: "Ucapkan “terima kasih”.",
        answerResult:
          "Bercakap-cakap, pastinya menyenangkan. Tukang bongkar muat pasti juga senang mendapatkan uang tip atas pekerjaannya. Jumlahnya akan ditambahkan ke harga order.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },
];

export default questions;
